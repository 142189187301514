<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/enroll-form.css";
</style>
<style scoped>
  .msgName {
    width: 155px;
    min-width: 155px;
  }
</style>
<script>
  import Layout from "../../layouts/main";
  import CheckHeader from "@/components/check-header";
  import CheckTableHeader from "@/views/pages/user/checkTable-header";
  import $ from 'jquery'
 import changeExam from '@/components/change-exam.vue'
  import {
    getNowExam,
  } from "@/api/admin/exam/examRecord.js"

  /**
   * 学历审核
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      CheckTableHeader,
      changeExam


    },
    data() {
      return {
        title: "考试报名信息审核  / ",
        title2:"内江师范学院2021年12月考核招聘艺体类工作人员招聘考试",
        subTitle: "切换报名",
        items: [{
            text: "用户首页"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        defaultvalue: 1,
        currentpage: 1,
        ksmc:"",
        ksbmbh:"",
        tablelist: [{
            index: 1,
            bkzw: "辅导员",
            name: "王小川",
            csny: "1990-09-09",
            xb: "女",
            zgxl: "本科",
            zgxw: "学士",
            byxxzy: "西南交通大学计算机科学与技术",
            byzh: "510101200112121234",
            xwzh: "510101200112121234",
          },
          {
            index: 2,
            bkzw: "辅导员",
            name: "王小川",
            csny: "1990-09-09",
            xb: "女",
            zgxl: "本科",
            zgxw: "学士",
            byxxzy: "西南交通大学计算机科学与技术",
            byzh: "510101200112121234",
            xwzh: "510101200112121234",
          },
          {
            index: 3,
            bkzw: "辅导员",
            name: "王小川",
            csny: "1990-09-09",
            xb: "女",
            zgxl: "本科",
            zgxw: "学士",
            byxxzy: "西南交通大学计算机科学与技术",
            byzh: "510101200112121234",
            xwzh: "510101200112121234",
          },
        ],
        showmodal: false,
        tableTool: {
          xx: 1,
          zp: 1,
          xl: 1,
          jf: 1,
          zgsh: 1,
          xlsh: 1,
          zpsh: 1,
        },




        allData: [

          {
            label: "学历",
            key: "xl",
          },

          {
            label: "资格审核",
            key: "zgsh",
          },
          {
            label: "学历审核",
            key: "xlsh",
          },
          {
            label: "学位审核",
            key: "xwsh",
          },
        ],
        checkData: ['zgsh', 'xl'],
      };


    },
    methods: {
      getTool() {
        console.log(this.checkData)
        let check = this.checkData
        let all = this.tableTool
        for (var i in all) {
          if (check.indexOf(i) > -1) {
            this.tableTool[i] = 1
          } else {
            this.tableTool[i] = 0
          }
        }
        this.showmodal = false
      },
      guding() {
        var left = $("#tableDiv").scrollLeft(); //获取滚动的距离
        // if(left>245){return}
        var trs = $("#tableDiv table tr"); //获取表格的所有tr
        var table = $("#tableDiv table");
        let width = table.width()
        let parentWidth = $("#tableDiv table").parent().width();
        let currentWidth = parentWidth + left - width;
        // console.log(currentWidth)
        trs.each(function() { //对每一个tr（每一行）进行处理

          $(this).children().eq(11).css({
            "position": "relative",
            "top": "0px",
            "left": currentWidth
          });
          $(this).children().eq(12).css({
            "position": "relative",
            "top": "0px",
            "left": currentWidth
          });
          $(this).children().eq(13).css({
            "position": "relative",
            "top": "0px",
            "left": currentWidth
          });
          $(this).children().eq(14).css({
            "position": "relative",
            "top": "0px",
            "left": currentWidth
          });

        });
      },
      //获取切换的考试
      getLastExam() {
        getNowExam().then(res => {
          if (res.status) {
            this.ksbmbh = res.data.ksbmbh
            this.ksmc = res.data.ksmc
            // this.getList({ksbmbh:this.ksbmbh})
          }
        })
      },
    },

    mounted() {
      this.getLastExam()
      this.guding()
      $("#tableDiv").scroll(this.guding);

    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <CheckTableHeader />
            <div class="table-responsive border" id="tableDiv" style="max-width: 100%;">
              <table class="table light-table table-hover table-bordered table-scroll">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 50px"> <input type="checkbox" value /></th>
                    <th style="width: 100px">报考职位</th>
                    <th style="width: 100px">姓名</th>
                    <th style="width: 100px">出生年月 </th>
                    <th style="width: 100px">性别 </th>
                    <th style="width: 100px">最高学历 </th>
                    <th style="width: 100px">最高学位 </th>
                    <th style="width: 215px">毕业学校专业 </th>
                    <th style="width: 200px">毕业证号 </th>
                    <th style="width: 200px">学位证号 </th>
                    <th style="width: 100px">学信网状态 </th>
                    <th style="width: 50px" class="guding " v-if="tableTool.xx">学历 </th>
                    <th style="width: 82px" class="guding " v-if="tableTool.zgsh">资格审核 </th>
                    <th style="width: 82px" class="guding " v-if="tableTool.xlsh">学历审核 </th>
                    <th style="width: 82px" class="guding " v-if="tableTool.xlsh">学历审核 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="obj in tablelist" :key="obj.index">
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value id="defaultCheck1" />
                        <label class="form-check-label" for="defaultCheck1">{{obj.index}}</label>
                      </div>
                    </td>
                    <td>{{obj.bkzw}}</td>
                    <td>
                      <b-button v-b-modal.education variant="link" class="blue-font h30">{{obj.name}}</b-button>
                    </td>
                    <td>{{obj.csny}}</td>
                    <td>{{obj.xb}}</td>
                    <td>{{obj.zgxl}}</td>
                    <td>{{obj.zgxw}}</td>
                    <td>{{obj.byxxzy}}</td>
                    <td>{{obj.byzh}}</td>
                    <td>{{obj.xwzh}}</td>
                    <td class="text-info">通过</td>
                    <td class="tab-icon light-yellow-bg" v-if="tableTool.xx"><i
                        class="iconfont icon-a-ze-checkedCopy2  text-success"></i>
                    </td>
                    <td class="light-green-bg" v-if="tableTool.zgsh"><button type="button"
                        class="btn btn-info h30 ">通过</button></td>
                    <td class="light-pink-bg" v-if="tableTool.xlsh"><button type="button"
                        class="btn btn-danger h30 ">不通过</button></td>
                    <td class="light-blue-bg" v-if="tableTool.xlsh">
                      <b-button v-b-modal.person variant="secondary" class="blue-font h30">审核</b-button>
                    </td>

                  </tr>
                  
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <div class="all-count mr-4">共有 100条，20条/页，前往<div class="link-page m-2">2</div>页</div>
              <b-pagination v-model="defaultvalue" :total-rows="50" :per-page="10" aria-controls="my-table">
              </b-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 表格列数自定义 -->
    <b-modal id="tableSet" v-model="showmodal" centered title="表格自定义" title-class="font-18" size="lg" hide-footer>
      <div style="padding-left: 85px;">
        <el-transfer v-model="checkData" :data="allData" :titles="['所有列', '需要显示的列']"></el-transfer>
      </div>
      <div class="text-center mt-3"> <button type="button" class="btn btn-info mr-2 w-sm h30"
          @click="getTool">确定</button>
        <button type="button" class="btn btn-secondary w-sm h30" @click="showmodal = false"> 取消</button>
      </div>


    </b-modal>
    <!--批量审核弹窗 -->
    <b-modal id="modal-center" centered title="批量处理" title-class="font-18" hide-footer>
      <div class="red-font">您已批量选中数据 2345 条，请选择操作</div>
      <div class="mt-2">
        <div class="form-check "><input type="checkbox" value="" id="defaultCheck1" class="form-check-input"><label
            for="defaultCheck1" class="form-check-label">通过所选数据学历要求
          </label></div>
        <div class="form-check "><input type="checkbox" value="" id="defaultCheck2" class="form-check-input"><label
            for="defaultCheck2" class="form-check-label">不通过所选数据学历要求</label></div>
        <div class="form-check "><input type="checkbox" value="" id="defaultCheck3" class="form-check-input"><label
            for="defaultCheck3" class="form-check-label">重置（恢复到到审核初始状态）</label></div>
      </div>

      <textarea rows="3" class="form-control w-100 mt-2 line3" placeholder="审核备注，如有可输入"></textarea>
      <div class="flexList mt-2 check-modal"><button type="button" class="btn btn-info h30  mr-2">提交操作</button>
        <button type="button" class="btn btn-secondary h30  mr-2">取消操作</button>
      </div>
    </b-modal>

    <!-- 学历审核弹出 -->
    <b-modal id="education" title="学历要求审核" size="lg" centered title-class="font-18" hide-footer>
      <div class="msgContent flexList fs-xs">
        <div class="flexList col-sm-6 msgLabel">
          <div class="msgName ">姓名</div>
          <div class="msgVal  flexList">
            <div class="line1">李晓娜</div>
          </div>
        </div>
        <div class="flexList col-sm-6 msgLabel">
          <div class="msgName ">身份证号</div>
          <div class="msgVal  flexList ">
            <div class="line1">510101201611222345</div>
          </div>
        </div>
        <div class="flexList col-sm-6 msgLabel">
          <div class="msgName ">最高学历</div>
          <div class="msgVal  flexList">
            <div class="line1"></div>
          </div>
        </div>
        <div class="flexList col-sm-6 msgLabel">
          <div class="msgName ">最高学位</div>
          <div class="msgVal  flexList">
            <div class="line1"></div>
          </div>
        </div>
        <div class="flexList col-sm-6 msgLabel">
          <div class="msgName ">毕业证书号</div>
          <div class="msgVal  flexList">
            <div class="line1">5101012020120212343</div>
          </div>
        </div>
        <div class="flexList col-sm-6 msgLabel">
          <div class="msgName ">学位证书号</div>
          <div class="msgVal  flexList">
            <div class="line1">5101012020120212343</div>
          </div>
        </div>
        <div class="flexList col-sm-6 msgLabel">
          <div class="msgName ">毕业学校专业</div>
          <div class="msgVal  flexList">
            <div class="line1">辅导员</div>
          </div>
        </div>
        <div class="flexList col-sm-6 msgLabel">
          <div class="msgName ">学信网状态</div>
          <div class="msgVal  flexList">
            <div class="line1"></div>
          </div>
        </div>
      </div>
      <textarea rows="4" class="form-control w-100 mt-2 line3" placeholder="审核备注，如有可输入"></textarea>
      <div class="text-center mt-2"><button type="button" class="btn btn-info h30  w-lg mr-2">审核通过</button>
        <button type="button" class="btn btn-secondary h30  w-lg">审核不通过</button>
      </div>
    </b-modal>

    <!-- 更多组合条件 -->
    <b-modal id="more-condition" centered title="组合查询条件设置" size="lg" title-class="font-18" hide-footer>
      <div class="d-flex mb-2">
    
        <!-- <b-dropdown size="sm" class="msgValInput col-sm-3 p-0 mr-1 h30" variant="outline-light">
          <template v-slot:button-content>
            年龄
            <i class="mdi mdi-chevron-down"></i>
          </template>
          <b-dropdown-item-button></b-dropdown-item-button>
          <b-dropdown-item-button></b-dropdown-item-button>
          <b-dropdown-item-button></b-dropdown-item-button>
        </b-dropdown> -->
        <select class="form-control msgValInput ol-sm-3  mr-1 h30">
          <option>年龄</option>
          <option>不可用</option>
        </select>
        <select class="form-control msgValInput ol-sm-3  mr-1 h30">
          <option>大于</option>
          <option>小于</option>
          <option>等于</option>
        </select>
        <input class="msgValInput  col-sm-4 h30 form-control" value="1991-12-01">
        <div class="condition-icon ml-2"><i class="iconfont icon-plussquare mr-2"></i>
          <i class="iconfont icon-minussquare"></i>
        </div>
      </div>
      <div class="d-flex mb-2">
        <select class="form-control msgValInput ol-sm-3  mr-1 h30">
          <option>出生地</option>
          <option>不可用</option>
        </select>
        <select class="form-control msgValInput ol-sm-3  mr-1 h30">
          <option>大于</option>
          <option>小于</option>
          <option>等于</option>
        </select>
    
        <input class="msgValInput  col-sm-4 h30 form-control" value="1991-12-01">
        <div class="condition-icon ml-2"><i class="iconfont icon-plussquare mr-2"></i>
          <i class="iconfont icon-minussquare"></i>
        </div>
      </div>
      <div class="d-flex  mb-2">
        <select class="form-control msgValInput ol-sm-3  mr-1 h30">
          <option>学历</option>
          <option>不可用</option>
        </select>
        <select class="form-control msgValInput ol-sm-3  mr-1 h30">
          <option>大于</option>
          <option>小于</option>
          <option>等于</option>
        </select>
    
        <input class="msgValInput  col-sm-4 h30 form-control" value="1991-12-01">
        <div class="condition-icon ml-2"><i class="iconfont icon-plussquare mr-2"></i>
          <i class="iconfont icon-minussquare"></i>
        </div>
      </div>
      <div class="d-flex mb-2 ">
        <select class="form-control msgValInput ol-sm-3  mr-1 h30">
          <option>政治面貌</option>
          <option>不可用</option>
        </select>
        <select class="form-control msgValInput ol-sm-3  mr-1 h30">
          <option>大于</option>
          <option>小于</option>
          <option>等于</option>
        </select>
    
        <input class="msgValInput  col-sm-4 h30 form-control" value="1991-12-01">
        <div class="condition-icon ml-2"><i class="iconfont icon-plussquare mr-2"></i>
          <i class="iconfont icon-minussquare"></i>
        </div>
      </div>
      <div class="d-flex mb-2 ">
        <select class="form-control msgValInput ol-sm-3  mr-1 h30">
          <option>民族</option>
          <option>不可用</option>
        </select>
        <select class="form-control msgValInput ol-sm-3  mr-1 h30">
          <option>大于</option>
          <option>小于</option>
          <option>等于</option>
        </select>
    
        <input class="msgValInput  col-sm-4 h30 form-control" value="1991-12-01">
        <div class="condition-icon ml-2"><i class="iconfont icon-plussquare mr-2"></i>
          <i class="iconfont icon-minussquare"></i>
        </div>
      </div>
      <div class="flexList mt-3 check-modal"><button type="button" class="btn btn-info h30  mr-2">执行查询</button>
        <button type="button" class="btn btn-secondary h30  mr-2">取消</button>
      </div>
    </b-modal>
    <changeExam></changeExam>
    <!-- 弹窗结束 -->
  </Layout>
</template>
